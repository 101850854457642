import React from "react";
import ReactDOM from "react-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import App from "./App";

import * as serviceWorker from "./serviceWorker";

import "./App.scss";

Bugsnag.start({
  apiKey: "4bc7be7bfdeab57163273fa9329d585c",
  appVersion: "2.0.135",
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ["production", "staging"],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
